import React, { useState } from "react";
import ProductModal from "../../modal/ProductModal";
import { useSpring, animated } from "react-spring";

import "./Products.css";

import productsData from "../../data/products.json";

const Products = () => {
  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 1000 },
  });
  const [selectedProduct, setSelectedProduct] = useState(null);
  const products = productsData;

  const openModal = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="products">
      <h1 className="product-header">Food Snacks</h1>
      <div className="product-list">
        {products &&
          products.map((product) => (
            <animated.section style={animation}>
              <div
                className="product"
                key={product.id}
                onClick={() => openModal(product)}
              >
                <h3>{product.name}</h3>
                <img
                  src={require(`../../images/namkeen/${product.image}`)}
                  alt={product.name}
                />
              </div>
            </animated.section>
          ))}
      </div>
      <ProductModal selectedProduct={selectedProduct} closeModal={closeModal} />
    </div>
  );
};

export default Products;
