import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

import "./SocialMediaLinks.css";

const SocialMediaLinks = () => {
  const facebookUrl = "https://www.facebook.com/pnkjmshra";
  const instagramUrl = "https://www.instagram.com/framesbypankaj";

  return (
    <div className="social-media-panel">
      <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
        <FaFacebook className="social-icon" />
      </a>
      <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
        <FaInstagram className="social-icon" />
      </a>
      <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
        <FaTwitter className="social-icon" />
      </a>
    </div>
  );
};

export default SocialMediaLinks;
