import React from "react";
import membersData from "../../data/members.json";

import "./Contact.css";
import SocialMediaLinks from "../../component/socialmedia/SocialMediaLinks";

const Contact = () => {
  const members = membersData;

  return (
    <>
      <div className="contacts">
        <div class="contact-form">
          <h2 class="section-header">Contact Us</h2>
          <form>
            <div class="form-group">
              <label for="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter your name"
              />
            </div>
            <div class="form-group">
              <label for="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter your email"
              />
            </div>
            <div class="form-group">
              <label for="message">Message</label>
              <textarea
                id="message"
                name="message"
                placeholder="Enter your message"
              ></textarea>
            </div>
            <div class="form-group">
              <button type="submit">Submit</button>
            </div>
          </form>
        </div>
        <div class="board-members">
          {members.map((member) => (
            <div className="board-member" key={member.id}>
              <img
                src={require(`../../images/directors/${member.image}`)}
                alt={member.name}
                height={200}
                width={200}
              />
              <div className="board-member-detail">
                <h3>{member.name}</h3>
                <p>{member.position}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SocialMediaLinks />
    </>
  );
};

export default Contact;
