import React from "react";
import Modal from "react-modal";

import "./ProductModal.css";

Modal.setAppElement("#root");

const ProductModal = (props) => {
  return (
    <Modal
      isOpen={!!props.selectedProduct}
      onRequestClose={props.closeModal}
      contentLabel="Product Details"
      className="modal"
    >
      {props.selectedProduct && (
        <div className="modal-content">
          <div className="modal-header">
            <h2>Details of {props.selectedProduct.name}</h2>
            <button className="modal-close" onClick={props.closeModal}>
              &times;
            </button>
          </div>
          <div className="modal-body">
            <div className="img-container">
              <img
                src={require(`../images/namkeen/${props.selectedProduct.image}`)}
                height={400}
                width={400}
                alt={props.selectedProduct.name}
              />
            </div>
            <div class="details-container">
              <ul>
                <li>
                  <span class="label">Name</span>
                  <span class="value">{props.selectedProduct.name}</span>
                </li>
                <li>
                  <span class="label">Description</span>
                  <span class="value">{props.selectedProduct.description}</span>
                </li>
                <li>
                  <span class="label">Taste</span>
                  <span class="value">{props.selectedProduct.taste}</span>
                </li>
                <li>
                  <span class="label">Ingredients</span>
                  <span class="value">{props.selectedProduct.ingredients}</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <button onClick={props.closeModal}>Close</button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default ProductModal;
