import React from "react";
import "./YoutubeVideo.css";

const YouTubeVideo = (props) => {
  return (
    <div className="video-container">
      <iframe
        src={props.url}
        title={props.header}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default YouTubeVideo;
