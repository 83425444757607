import React from "react";
import { useSpring, animated } from "react-spring";

import "./Panel.css";
import YouTubeVideo from "../youtube/YoutubeVideo";

const Panel = (props) => {
  const animation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 3000 },
  });
  return (
    <div className="panel">
      <animated.section style={animation}>
        <h2 className="header">{props.header}</h2>
        <YouTubeVideo
          url={props.url}
          header={props.header}
          className="youtube"
        />
        <p className="content">{props.content}</p>
      </animated.section>
    </div>
  );
};

export default Panel;
