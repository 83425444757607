import React from "react";
import ProductSlider from "../../component/silder/ProductSlider";
import Panel from "../../component/panel/Panel";

import panelsData from "../../data/panel.json";

import "./Home.css";

const Home = () => {
  const panels = panelsData;
  return (
    <div className="home">
      <ProductSlider />
      <hr class="custom-line" />
      {panels &&
        panels.map((panel) => (
          <>
            <Panel
              header={panel.header}
              content={panel.content}
              url={panel.url}
            />
            <hr class="custom-line" />
          </>
        ))}
    </div>
  );
};

export default Home;
