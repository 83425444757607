import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Home from "./pages/home/Home";
import Products from "./pages/products/Products";
import Contact from "./pages/contact/Contact";
import Navbar from "./component/navigation/Navbar";
import Sidebar from "./component/sidebar/Sidebar";
import "./App.css";

function App() {
  const [isopen, setisopen] = useState(false);
  const toggle = () => {
    setisopen(!isopen);
  };

  return (
    <Router>
      <div>
        <header>
          <Navbar toggle={toggle} />
          <Sidebar isopen={isopen} toggle={toggle} />
        </header>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        <footer className="footer">
          <div>2023 FlavoursOfIndia. All rights reserved</div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
