import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./ProductSlider.css";

const ProductSlider = () => {
  return (
    <Carousel
      showStatus={false}
      infiniteLoop
      autoPlay
      useKeyboardArrows
      transitionTime={1000}
      width="100%"
    >
      <div className="slide-holder">
        <div>
          <img
            src={require(`../../images/products/namkeen.jpg`)}
            alt={"namkeen"}
            key={1}
          />
          <img
            src={require(`../../images/products/namkeen2.png`)}
            alt={"namkeen2"}
            key={1}
          />
          <img
            src={require(`../../images/products/namkeen3.png`)}
            alt={"namkeen3"}
            key={1}
          />
        </div>
        <div className="text-container">
          <h2>Indian Namkeen</h2>
          <p>
            Indian namkeen refers to a variety of savory snacks popular in
            India. These crispy and flavorful treats come in a wide range of
            options, including bhujia, sev, chivda, and more. Made with
            ingredients like chickpea flour, spices, and nuts, namkeen is a
            delightful accompaniment to tea and an essential part of Indian
            snacking culture.
          </p>
        </div>
      </div>
      <div className="slide-holder">
        <div>
          <img
            src={require(`../../images/products/pottery.png`)}
            alt={"pottery"}
            key={2}
          />
          <img
            src={require(`../../images/products/pottery2.png`)}
            alt={"pottery2"}
            key={2}
          />
          <img
            src={require(`../../images/products/pottery3.png`)}
            alt={"pottery3"}
            key={2}
          />
        </div>
        <div className="text-container">
          <h2>Homemade terracota pottery</h2>
          <p>
            Homemade terracotta pots from India are handcrafted with natural
            clay, offering eco-friendly and breathable containers for plants.
            With unique designs, they showcase artistic traditions and provide
            thermal regulation. These versatile pots age gracefully, developing
            a rustic patina. Made with skill and cultural heritage, they add
            charm to any gardening space
          </p>
        </div>
      </div>
      <div className="slide-holder">
        <div>
          <img
            src={require(`../../images/products/tableware.png`)}
            alt={"tableware"}
            key={3}
          />
          <img
            src={require(`../../images/products/tableware2.png`)}
            alt={"tableware2"}
            key={3}
          />
          <img
            src={require(`../../images/products/tableware3.png`)}
            alt={"tableware3"}
            key={3}
          />
        </div>
        <div className="text-container">
          <h2>Indian biodegradable tableware</h2>
          <p>
            Indian leaf plates, also known as "patravali" or "bota," are
            eco-friendly and biodegradable alternatives to disposable plates.
            Made from leaves of the areca palm tree, these plates are sturdy and
            suitable for serving meals. They are commonly used in traditional
            gatherings, festivals, and weddings, promoting sustainability and
            reducing plastic waste.
          </p>
        </div>
      </div>
    </Carousel>
  );
};

export default ProductSlider;
